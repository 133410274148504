import { createSelector } from '@ngrx/store'
import { JobsiteDto } from 'app/shared/remote-services/dtos/jobsite.dto'
import { FavoriteKpiDto } from '../../../jobsites-management/jobsite-summary/jobsite-kpi/models/jobsite-favorite-kpi.dto'
import { CompanyDto } from '../../models/company.dto'
import {
  getRoCompanies,
  getUserCompany,
} from '../companies/companies.selectors'
import { getJobsiteId } from '../router/router.selectors'
import { getState as getCoreState, State } from '../state'
import { State as JobsiteState } from './jobsite.reducer'
import {
  CsvPreferencesDto,
  DEFAULT_CSV_PREFERENCES,
} from '../../models/companyCsvPreferencesInput.dto'
import { ContractualTolerances } from '../../../shared/models/contractual-tolerances.model'

export const getState = createSelector<State, [State], JobsiteState>(
  getCoreState,
  (state: State) => state.jobsite,
)

export const getLoaded = (routeJobsiteId: string) =>
  createSelector(
    getState,
    (state: JobsiteState) => state.currentJobsite?.id === routeJobsiteId,
  )

export const getJobsite = createSelector(
  getState,
  getJobsiteId,
  (state, jobsiteId) => {
    if (state?.currentJobsite?.id !== jobsiteId) {
      return undefined
    }
    return state.currentJobsite
  },
)

export const getJobsiteContractNumber = createSelector(
  getJobsite,
  jobsite => jobsite?.contractNumber,
)

export const getJobsiteCompanyIds = createSelector(
  getJobsite,
  jobsite => jobsite?.companyIds ?? [],
)

export const getJobsiteCompany = createSelector(
  getJobsiteCompanyIds,
  getRoCompanies,
  getUserCompany,
  (
    companyIds,
    roCompanies,
    userCompany: CompanyDto | undefined,
  ): CompanyDto | undefined => {
    if (userCompany && companyIds.includes(userCompany?.id)) {
      return userCompany
    }

    return roCompanies.find(roc => companyIds.includes(roc?.id))
  },
)

export const getJobsiteStatus = createSelector(
  getJobsite,
  jobsite => jobsite?.status,
)

export const getJobsiteConfigurable = createSelector<
  State,
  [JobsiteDto, CompanyDto],
  boolean
>(getJobsite, getUserCompany, (jobsite, usercompany) => {
  if (jobsite?.companyIds && usercompany) {
    return jobsite.companyIds.includes(usercompany.id)
  }
  return true
})

export const getJobsiteName = createSelector(
  getJobsite,
  jobsite => jobsite?.name,
)

export const getJobsiteFavoriteKpi = createSelector<
  State,
  [JobsiteDto],
  FavoriteKpiDto[]
>(getJobsite, jobsite => {
  return jobsite?.favoriteKPI ?? []
})

export const getLastCheckValues = createSelector(
  getState,
  state => state.lastCheckAvailabilityValues,
)

export const getCheckResponse = createSelector(
  getState,
  state => state.checkAvailability,
)

export const getJobsiteEmptyLocation = createSelector(
  getJobsite,
  jobsite =>
    jobsite?.location?.latitude == null || jobsite?.location?.longitude == null,
)

export const getJobsiteContractualTolerances = createSelector<
  State,
  JobsiteDto,
  ContractualTolerances
>(getJobsite, jobsite => ({
  contractualToleranceLR: jobsite?.contractualToleranceLR,
  contractualToleranceFB: jobsite?.contractualToleranceFB,
}))

export const getJobsiteCSVPreference = createSelector<
  State,
  [JobsiteDto, CompanyDto],
  CsvPreferencesDto
>(getJobsite, getJobsiteCompany, (jobsite: JobsiteDto, company: CompanyDto) => {
  const jobsiteRegion = company?.regions.find(region =>
    jobsite?.regionIds.includes(region.id),
  )
  const csvPreferences: CsvPreferencesDto = {
    csvSeparator:
      jobsiteRegion?.csvSeparator ??
      company?.defaultCsvSeparator ??
      DEFAULT_CSV_PREFERENCES.csvSeparator,
    csvDecimalSeparator:
      jobsiteRegion?.csvDecimalValueSeparator ??
      company?.defaultCsvDecimalSeparator ??
      DEFAULT_CSV_PREFERENCES.csvDecimalSeparator,
  }
  return csvPreferences
})
