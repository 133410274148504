// Build information, automatically generated by `ng-info`
const build = {
    version: "21.2.0",
    timestamp: "Fri Nov 15 2024 00:36:13 GMT+0100 (Central European Standard Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "6d9c8e",
        fullHash: "6d9c8ed3dba4633a59c23d2b39f55c9c694b4076"
    }
};

export default build;